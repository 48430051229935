<template>
  <SfImage
    class="m-brands-carousel__image"
    :alt="name"
    :src="urlLogoBrand"
  />
</template>
<script>
import { getImageByFetch } from 'theme/helpers'
import { SfImage } from '@storefront-ui/vue';
import { isServer } from '@vue-storefront/core/helpers';
export default {
  name: 'MRenderImage',
  components: {
    SfImage
  },
  props: {
    brandId: String,
    name: String
  },
  data () {
    return {
      urlLogoBrand: null
    }
  },
  async created () {
    if (isServer) return

    const response = await this.getCategoryPreviewImage(this.brandId)
    const parser = new DOMParser();
    const doc = parser.parseFromString(await response.text(), 'image/svg+xml');
    const getDoc = doc.querySelector('image')

    if (getDoc) {
      const getLink = getDoc.getAttribute('xlink:href')
      this.urlLogoBrand = getLink
    } else {
      this.urlLogoBrand = response.url
    }
  },
  methods: {
    getCategoryPreviewImage (itemId) {
      return getImageByFetch(itemId)
    }
  }
}
</script>
