<template>
  <ASmoothReflow
    v-intersect.once="{ handler: onIntersect, cfg: { rootMargin: '0px' }}"
    class="o-home-cafe-carousel__wrapper"
  >
    <OSection
      v-if="hasIntersected && !isLoading && categories.length"
      v-show="products.length"
      :title-heading="$t('VARUS CAFE')"
      class="o-home-cafe-carousel"
      is-centered
      is-image
      :background="`#E6EBE5`"
    >
      <template #mobile-banner>
        <div class="o-section__mobile-banner">
          <div class="sf-banner-sm sf-banner-sm--mobile">
            <MBannerCarousel
              :banners="getVarusCafeBanners"
            />
          </div>
        </div>
      </template>
      <template #link>
        <SfLink
          :link="localizedRoute('/varuscafe')"
          class="sf-link--primary all-products-link"
        >
          {{ $t('All products') }}
        </SfLink>
      </template>
      <template #tags>
        <MProductTags
          :tags="tagCategories"
          @setActiveCategory="setCategory"
          :is-all-tags-required="true"
        />
      </template>

      <template #banner>
        <div class="varus-cafe-banner">
          <div class="sf-banner-sm sf-banner-sm--desktop">
            <MBannerCarousel
              :banners="getVarusCafeBanners"
            />
          </div>
        </div>
      </template>
      <MCafeCarousel
        v-if="showCarousel"
        :products="selectedCategoryProducts.slice(0, carouselSize)"
        :es-url-param="esUrlParam"
      />
    </OSection>
    <div
      v-else-if="isLoading && !categories.length"
      class="o-home-cafe-carousel__wrapper--height"
    >
      <SfLoader :loading="true" />
    </div>
  </ASmoothReflow>
</template>

<script>

import { SfLink } from '@storefront-ui/vue';
import OSection from 'theme/components/organisms/o-section';
import MCafeCarousel from 'theme/components/molecules/m-cafe-carousel';
import MProductTags from 'theme/components/molecules/m-product-tags';
import config from 'config'
import { mapActions, mapGetters } from 'vuex';
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import intersect from '../../directives/intersect';
import GoogleTagManager from 'theme/mixins/gtm'
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel';
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'

import {
  getRelatedCategories
} from 'theme/helpers';

export default {
  name: 'OHomeCafeCarousel',
  components: {
    OSection,
    SfLink,
    MCafeCarousel,
    MProductTags,
    ASmoothReflow,
    SfLoader,
    MBannerCarousel
  },
  mixins: [GoogleTagManager],
  props: {
    products: {
      type: Array,
      default: () => []
    },
    amount: {
      type: Number,
      default: () => 4
    },
    esUrlParam: {
      type: String,
      default: ''
    }
  },
  directives: { intersect },
  data () {
    return {
      selectedCategoryIndex: -1,
      showCarousel: true,
      carouselSize: config.entities.product.varusCafeSize,
      hasIntersected: false
    }
  },
  computed: {
    ...mapGetters({
      getVarusCafeBanners: 'promoted/getVarusCafeBanners',
      varusCafeLoading: 'category-extension/getVarusCafeCategoriesLoading',
      varusCafeCategories: 'category-extension/getVarusCafeCategories',
      shippingDetails: 'shipping/getShippingDetails'
    }),
    cafeProductsMainWidgetId () {
      return config.esputnik?.widgetIds?.cafeProductsMain;
    },
    categories () {
      return getRelatedCategories(this.products, this.varusCafeCategories)
    },
    isLoading () {
      return (this.varusCafeLoading && !this.categories.length)
    },
    banner () {
      this.getVarusCafeBanners.forEach(e => {
        e.image = {
          mobile: getThumbnailPath(e.image_mobile),
          desktop: getThumbnailPath(e.image_desktop)
        }
      });
      return this.getVarusCafeBanners[0]
    },
    selectedCategoryProducts () {
      if (this.selectedCategoryIndex === -1) {
        return this.products;
      }

      if (!this.categories.length) {
        return [];
      }

      return this.categories[this.selectedCategoryIndex]?.products || [];
    },
    tagCategories () {
      return this.categories.slice(0, this.amount)
    },
    productsSku () {
      return this.products.map(e => e.sku)
    }
  },
  watch: {
    selectedCategoryIndex () {
      this.updateProductsCarousel()
    },
    productsSku (newValue, oldValue) {
      const valueChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue)
      if (!newValue || !valueChanged) return
      this.updateProductsCarousel()
    }
  },
  methods: {
    ...mapActions({
      loadVarusCafe: 'category-extension/loadVarusCafe'
    }),
    async onIntersect (entry) {
      if (!entry.isIntersecting || this.hasIntersected === true) return

      this.hasIntersected = true

      await this.loadVarusCafe()
    },
    setCategory (index) {
      this.selectedCategoryIndex = index
    },
    updateProductsCarousel () {
      this.showCarousel = false

      this.$nextTick(() => {
        this.showCarousel = true
      })
    },
    onBannerShow (banner) {
      const payload = [
        {
          item_name: banner.title || 'VARUS CAFE banner', // Name or ID is required.
          item_id: banner.id,
          price: 'price',
          item_brand: 'item_brand',
          item_category: 'item_category',
          promotion_id: banner.id,
          promotion_name: banner.title || 'VARUS CAFE banner',
          creative_name: 'creative_name',
          creative_slot: 'creative_slot',
          location_id: 'varus_cafe_banner',
          index: 'index',
          quantity: 1
        }
      ]

      this.gtmBannerHandler(payload, 'view_promotion')
    }
  },
  mounted () {
    this.onBannerShow(this.banner || {})
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

  .only-banner {
    cursor: pointer;
  }

  .varus-cafe-banner {
    width: px2rem(389);
    margin-right: var(--spacer-22);
    position: relative;
  }

  .sf-banner-cafe {
    &__title {
      font-size: var(--font-size-14);
      font-weight: 600;
      line-height: var(--font-size-17);
      letter-spacing: 0;
      text-align: left;
      color: var(--black);
      margin-top: 0;
      margin-bottom: var(--spacer-4);
      max-width: px2rem(230);

      @media (min-width: $tablet-min) {
        font-weight: 600;
        font-size: var(--font-size-20);
        line-height: var(--font-size-24);
        margin-bottom: var(--spacer-20);
      }
      @include for-desktop {
        margin-bottom: var(--spacer-28);
      }
    }

    &__description {
      font-size: var(--font-size-16);
      font-weight: 400;
      line-height: px2rem(21.2);
      letter-spacing: 0;
      text-align: left;

      @include only-mobile {
        margin: var(--spacer-5) 0 var(--spacer-14);
      }

      @media (min-width: $tablet-min) {
        margin: var(--spacer-11) 0 var(--spacer-24);
        font-size: var(--font-size-24);
        font-weight: 400;
        line-height: var(--font-size-30);
      }
    }
  }

  .o-home-cafe-carousel {
    &__wrapper {
      &--height {
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ::v-deep .o-section {
        @media (max-width: $tablet-max) {
          overflow-x: hidden;
        }

        &__bg {
          &--left {
            top: var(--spacer-30);
            left: var(--spacer-30);

            @media (max-width: $tablet-max) {
              width: 40vw;
              left: var(--spacer-5);
              top: var(--spacer-10);
            }
          }

          &--right {
            top: var(--spacer-60);
            right: var(--spacer-30);
            width: px2rem(343);

            @media (max-width: $tablet-max) {
              width: 35vw;
              top: unset;
              bottom: px2rem(160);
              right: calc(-1 * var(--spacer-20));
            }
          }
        }
      }
    }

  ::v-deep {
    .o-section__content {
      @media (min-width: $tablet-min) {
        padding-left: var(--spacer-sm);
        padding-right: var(--spacer-sm);
      }

      @include for-mobile {
        .sf-banner {
          display: none;
        }
      }
    }

    .o-section__heading {
      @include for-mobile {
        display: grid;
        grid-template-areas:
          "a b"
          "c c";
      }
      @media (min-width: $tablet-min) {
        padding-left: var(--spacer-sm);
      }
    }

    .sf-heading__title--h3 {
      white-space: nowrap;
      @include for-mobile {
        grid-area: a;
        .sf-link.orange-part {
          display: none;
        }
      }
    }
    .all-products-link {
      @include for-mobile {
        grid-area: b;
        text-align: right;
      }
    }
    .m-product-tags {
      @include for-mobile {
        grid-area: c;
        padding-left: var(--spacer-10);
      }
    }

    .sf-button--base {
      @media (min-width: $tablet-min) {
        min-width: 180px;
      }
    }

    @include for-mobile {
      .o-section__heading {
        flex-wrap: wrap;
        padding-bottom: var(--spacer-20);
        align-items: flex-end;
      }
    }

    .m-cafe-carousel {
      max-width: 100%;

      @include for-desktop {
        .sf-carousel__slides {
          min-height: px2rem(310);
        }
      }

      * {
        box-sizing: border-box;
      }
    }

    .sf-banner-sm {
      width: 100%;
      background-size: contain;
      background-position: 100% 100%;

      .sf-banner__container {
        justify-content: flex-start;
      }

      @include for-desktop {
        --banner-padding: 0;
        background-size: cover;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;

        .sf-banner__container {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          padding: 24px 24px 45px;
          box-sizing: border-box;
          justify-content: flex-start;
        }
      }

      .sf-link {
        &--call-to-action {
          background-color: var(--orange);
          min-width: px2rem(131);
          height: px2rem(36);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: var(--font-sm);
          color: var(--white);
          font-weight: var(--font-medium);
          line-height: px2rem(24);

          &:hover {
            background: var(--orange-pressed);
            color: var(--white);
          }

          @media (min-width: $tablet-min) {
            min-width: px2rem(159);
            display: flex;
            justify-content: center;
            height: px2rem(40);
            align-items: center;
            padding: 0;
            font-size: var(--font-base);
            font-weight: var(--font-medium);
            line-height: var(--font-xl);
          }
        }
      }

      &.sf-banner-sm--mobile {
        .sf-banner.sf-banner-plp {
          width: 100%;
          background-size: contain;
          background-position: 100% 100%;
          min-height: px2rem(139);
        }
      }

      @include for-desktop {
        &.sf-banner-sm--desktop {
          .sf-banner.sf-banner-plp {
            min-height: px2rem(320);
            background-position-x: center;
          }
        }
      }

      @include for-mobile {
        &.sf-banner-sm--desktop {
          display: none;
        }
      }

      @include for-tablet {
        &.sf-banner-sm--mobile {
          .sf-banner.sf-banner-plp {
            min-height: px2rem(215);
          }
        }
      }

      .product-carousel.slider-carousel {
        .glide__track {
          padding-top: var(--spacer-20);
        }

        .sfo-carousel__bullets {
          padding-top: var(--spacer-10);
        }
      }
    }
  }
}
</style>
